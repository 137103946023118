import { getAuth, onAuthStateChanged } from "firebase/auth"
import {getStorage,ref
    ,uploadBytesResumable,getDownloadURL} from "firebase/storage"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Spinner from "../components/Spinner"
import { v4 as uuidv4 } from "uuid"
import {doc,serverTimestamp,updateDoc,getDoc} from "firebase/firestore"
import {db} from '../firebase.config'
import { toast } from "react-toastify"

const EditListing = () => {
const [loading,setLoading]=useState(true)
const [listing,setListing]=useState(null)
const [formData,setFormData]=useState({
    type:'rent',
    name:'',
    bedrooms:1,
    bathrooms:1,
    parking:false,
    furnished:false,
    address:'',
    offer:true,
    regularPrice:0,
    discountedPrice:0,
    images:{},
    latitude:0,
    longitude:0,
})
const {type,
name,
bedrooms,
bathrooms,
parking,
furnished,
address,
offer,
regularPrice,
discountedPrice,
images,
latitude,
longitude}=formData
const auth=getAuth()
const navigate=useNavigate()
const params=useParams()
const isMounted=useRef(true)

const onSubmit=async(e)=>{
    e.preventDefault()
    setLoading(true)
    const storeImage=async(image)=>{
    return new Promise((resolve,reject)=>{
        const storage=getStorage()
        const fileName=`${auth.currentUser.uid}-${image.name}-${uuidv4()}`
        const storageRef=ref(storage,'images/' + fileName)
        const uploadTask=uploadBytesResumable(storageRef,image)
        uploadTask.on('state_changed', 
        (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
            case 'paused':
            console.log('Upload is paused');
            break;
            case 'running':
            console.log('Upload is running');
            break;
        }
        }, 
        (error) => {
        reject(error)
        }, 
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
            });
            }
        );
        })
    }
    const imageUrls=await Promise.all(
        [...images].map((image)=>storeImage(image))
    ).catch(()=>{
        setLoading(false)
        toast.error('Cannot upload at the moment')  
        return
    })
    const formDataCopy={
        ...formData,
        imageUrls,
        timestamp:serverTimestamp(),
        location:address,
        geolocation:{
            lat:latitude,
            lng:longitude
        }
    }
    delete formDataCopy.latitude
    delete formDataCopy.longitude
    delete formDataCopy.images
    delete formDataCopy.address
    !formDataCopy.offer && delete formDataCopy.discountedPrice
    const docRef=doc(db,'listings',params.listingID)
    await updateDoc(docRef,formDataCopy)
    setLoading(false)
    toast.success('Listing Saved')
    navigate(`/category/${formDataCopy.type}/${docRef.id}`)
}

const onMutate=(e)=>{
    let boolean = null
    if (e.target.value==='true') {
      boolean=true  
    }
    if (e.target.value==='false') {
        boolean=false 
      }
    
    if (e.target.files) {
        setFormData({...formData,images:e.target.files})
    }
    if (!e.target.files) {
        setFormData({...formData,
        [e.target.id]:boolean??e.target.value})
    }
}

useEffect(()=>{
    if(listing&&listing.userRef!==auth.currentUser.uid){
        toast.error('You cannot Edit that listing')
    }
},[])

useEffect(()=>{
    const fetchListing=async()=>{
    const docRef=doc(db,'listings',params.listingID)
    const docSnap=await getDoc(docRef)
    if (docSnap.exists) {
        setListing(docSnap.data())
        setFormData({
            ...docSnap.data(),
            address:docSnap.data().location,
            latitude:docSnap.data().geolocation.lat ,
            longitude:docSnap.data().geolocation.lng
        })
        setLoading(false)
    }else{
        navigate('/')
    }
    }
    fetchListing()
},[params.listingID,navigate])

useEffect(() => {
    if (isMounted) {
        onAuthStateChanged(auth,(user)=>{
        if (user) {
           setFormData({...formData,userRef:user.uid}) 
        }else{
            navigate('/sign-in')
        }
        })
    }
    setLoading(false)
    return () =>{
        isMounted.current=false
    }
}, [isMounted])

if (loading) {
    return <Spinner />
}
  return (
    <div className="profile">
        <header>
            <p className="pageHeader">Edit Listing</p>
        </header>
        <main>
            <form onSubmit={onSubmit}>
                <label className="formLabel">Sell / Rent</label>
                <div className="formButtons">
                    <button type="button"
                    className={type==='sale'
                    ?'formButtonActive':'formButton'}
                    id='type' value='sale'
                    onClick={onMutate}
                    >
                        Sell
                    </button>
                    <button type="button"
                    className={type==='rent'
                    ?'formButtonActive':'formButton'}
                    id='type' value='rent'
                    onClick={onMutate}
                    >
                        Rent
                    </button>
                </div>
                <label className="formLabel">Name</label>
                <input type="text" className="formInputName" 
                id="name" value={name} onChange={onMutate}
                maxLength='32' minLength='10' required
                />
                <div className="formRooms flex">
                    <div>
                    <label className="formLabel">Bedrooms</label>
                    <input className="formInputSmall" type='number'
                    id='bedrooms' value={bedrooms}
                    onChange={onMutate}  min='1' max='50' required
                    />
                    </div>
                    <div>
                    <label className="formLabel">Bathrooms</label>
                    <input className="formInputSmall" type='number'
                    id='bathrooms' value={bathrooms}
                    onChange={onMutate}  min='1' max='50' required
                    />
                    </div>
                </div>
                <label className="formLabel">Parking</label>
                <div className="formButtons">
                    <button type="button"
                    className={parking
                    ?'formButtonActive':'formButton'}
                    id='parking' value={true}
                    onClick={onMutate}
                    >
                        Yes
                    </button>
                    <button type="button"
                    className={!parking && parking !==null
                    ?'formButtonActive':'formButton'}
                    id='parking' value={false}
                    onClick={onMutate}
                    >
                        No
                    </button>
                </div>
                <label className="formLabel">Furnished</label>
                <div className="formButtons">
                    <button type="button"
                    className={furnished
                    ?'formButtonActive':'formButton'}
                    id='furnished' value={true}
                    onClick={onMutate}
                    >
                        Yes
                    </button>
                    <button type="button"
                    className={!furnished && furnished !==null
                    ?'formButtonActive':'formButton'}
                    id='furnished' value={false}
                    onClick={onMutate}
                    >
                        No
                    </button>
                </div>
                <label className="formLabel">Address</label>
                <textarea
                className="formInputAddress"
                type='text'
                id="address"
                value={address}
                onChange={onMutate}
                required
                />
                <div className="formLatLng flex">
                    <div>
                    <label className="formLabel">Latitude</label>
                    <input className="formInputSmall" type='number'
                    id='latitude' value={latitude}
                    onChange={onMutate} required
                    />
                    </div>
                    <div>
                    <label className="formLabel">Longitude</label>
                    <input className="formInputSmall" type='number'
                    id='longitude' value={longitude}
                    onChange={onMutate} required
                    />
                    </div>
                </div>
                <label className="formLabel">Offer</label>
                <div className="formButtons">
                    <button type="button"
                    className={offer
                    ?'formButtonActive':'formButton'}
                    id='offer' value={true}
                    onClick={onMutate}
                    >
                        Yes
                    </button>
                    <button type="button"
                    className={!offer && offer !==null
                    ?'formButtonActive':'formButton'}
                    id='offer' value={false}
                    onClick={onMutate}
                    >
                        No
                    </button>
                </div>
                <label className="formLabel">Regular Price</label>
                <div className="formPriceDiv">
                    <input type="number" className="formInputSmall"
                    id="regularPrice" value={regularPrice}
                    onChange={onMutate} min='50' max='750000000'
                    required />
                    {type==='rent' && <p className="formPriceText">
                        $ / Month</p>}
                </div>
                {offer &&(
                <>
                <label className="formLabel">Discounted Price</label>
                <input type="number" className="formInputSmall"
                    id="discountedPrice" value={discountedPrice}
                    onChange={onMutate} min='50' max='750000000'
                    required={offer} />
                </>
                )}
                <label className="formLabel">Images</label>
                <p className="imagesInfo">
                The first image will be the cover (max 6).</p>
                <input type="file" className="formInputFile"
                id="images" onChange={onMutate} max='6'
                accept=".jpg,.png,.jpeg" multiple required
                />
                <button className="primaryButton createListingButton"
                type="submit">
                    Edit Listing
                </button>
            </form>
        </main>
    </div>
)
}

export default EditListing